import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { format, isAfter, isSameDay } from 'date-fns';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import MDTypography from 'components/MDTypography';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector, Provider } from 'react-redux';
import LoadingSpinner from 'components/Loader/LoadingSpinner';
import CircularProgress from '@mui/material/CircularProgress';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import CustomizedSnackbars from 'components/snackbar/Snackbar';
import PercentageLoader from 'components/Loader/PercentageLoader';
import { HeadingWrapperBox } from 'layouts/inventory/Inventory.styled';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { groupUsers, setFormName } from 'layouts/groups/store/groupUsersSlice';
import NotificationMessagePopup from 'components/Common/notificationMessagePopup';
import {
  displaySnackbar,
  resetSnackbar,
} from 'components/snackbar/store/SnackbarSlice';
import {
  Loader,
  TimeOutGrid,
  DialogHeader,
  ButtonWrapper,
  ButtonSpinner,
  DataGridSubHeading,
  ButtonSpinnerProgress,
  ButtonIcon,
  DropdownInputListBox,
  WhiteTooltip,
  NoWrap,
} from 'components/Ui/styled';
import {
  TimeOutDialog,
  requiredLimit,
  requiredField,
  dateFnsFormat,
  requiredTextLimit,
  characterLimit,
  dateFormat,
  minDateError,
  validDateError,
} from 'constants';
import moment from 'moment';
import RenderListDialog from 'components/Common/RenderListDialog';
import { MaterialUIControllerProvider } from 'context';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { store } from 'redux/store';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { Authenticator } from '@aws-amplify/ui-react';
import Dialog from '@mui/material/Dialog';
import {
  setIsLogoutTiming,
  setIsLogout,
  resetIsLogout,
} from 'examples/Navbars/store/logoutSlice';
import { resetSelectSemester } from 'layouts/applications/attendance/store/semesterSlice';
import { resetMyPermissionsData } from 'layouts/users/userPermissions/store/myPermissionsSlice';
import NoInfo from 'components/Common/NoInfo';
import { DialogFormLoader } from 'components/Ui/styled';
const { default: themeRtl } = require('assets/theme/theme-rtl');

export const renderComponent = (component) => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <CustomizedSnackbars />
      {component}
    </DashboardLayout>
  );
};

export const getDataGridHeading = (title, buttonText, handleClick) => {
  return (
    <HeadingWrapperBox>
      <MDTypography variant="h5" fontWeight="medium" data-testid={title}>
        {title}
      </MDTypography>
      <Button onClick={handleClick} variant="text">
        {buttonText}
      </Button>
    </HeadingWrapperBox>
  );
};

export const getDataGridSubHeading = (handleClick, buttonText) => {
  return (
    <DataGridSubHeading container>
      <Button onClick={handleClick} variant="text" data-testid={buttonText}>
        {buttonText}
      </Button>
    </DataGridSubHeading>
  );
};

export const getDialogHeading = (title, handleClose, dataTestid) => {
  return (
    <DialogHeader>
      <Typography variant="h6" data-testid={dataTestid}>
        {title}
      </Typography>
      <IconButton onClick={handleClose}>
        <CloseIcon data-testid="close-icon" />
      </IconButton>
    </DialogHeader>
  );
};

export const renderSaveCancelButtons = (
  handleCancel,
  handleSubmit,
  loading,
  disableSave,
  cancelText = 'Cancel'
) => {
  return (
    <ButtonWrapper container>
      <Button variant="text" onClick={handleCancel} data-testid="cancel-button">
        {cancelText}
      </Button>
      <ButtonSpinner>
        <Box sx={{ m: 1, position: 'relative' }}>
          <Button
            variant="text"
            onClick={handleSubmit}
            disabled={loading || disableSave}
            data-testid="save-button"
          >
            Save
          </Button>
          {loading && <ButtonSpinnerProgress size={24} />}
        </Box>
      </ButtonSpinner>
    </ButtonWrapper>
  );
};

export const getRowClassName = (params) =>
  params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd';

export const getRowHeight = () => {
  'auto';
};

export const getButtonSpinner = (
  loadingButton,
  handleButtonClick,
  buttonText,
  disableButton
) => {
  return (
    <ButtonSpinner>
      <Box sx={{ m: 1, position: 'relative' }}>
        <Button
          variant="text"
          disabled={loadingButton || disableButton}
          onClick={handleButtonClick}
        >
          {buttonText}
        </Button>
        {loadingButton && <ButtonSpinnerProgress size={24} />}
      </Box>
    </ButtonSpinner>
  );
};

export const dynamicSort = (property) => {
  var sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }

  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
};

const stringToColor = (string) => {
  let hash = 0;
  let i;
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
};

export const stringAvatar = (name) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
};

export const showBackdropLoader = (isLoading = false) => (
  <Backdrop
    data-testid="backdrop-loader"
    sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={isLoading}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
);

export const getYesNo = (handleNo, handleYes, loading) => {
  return (
    <ButtonWrapper container>
      <Button variant="text" onClick={handleNo}>
        No
      </Button>
      <ButtonSpinner>
        <Box sx={{ m: 1, position: 'relative' }}>
          <Button variant="text" onClick={handleYes} disabled={loading}>
            Yes
          </Button>
          {loading && <ButtonSpinnerProgress size={24} />}
        </Box>
      </ButtonSpinner>
    </ButtonWrapper>
  );
};

export const showLoader = () => (
  <Loader data-testid="loading">
    <LoadingSpinner />
  </Loader>
);

export const percentageLoader = (progress) => (
  <Loader>
    <PercentageLoader progress={progress} />
  </Loader>
);

export const renderEditDeleteIcon = (handleEdit, handleDelete) => {
  return (
    <>
      <ButtonIcon onClick={handleEdit} data-testid="edit-icon">
        <EditIcon />
      </ButtonIcon>
      <ButtonIcon onClick={handleDelete} data-testid="delete-icon">
        <DeleteIcon />
      </ButtonIcon>
    </>
  );
};
export const getSnackBar = (message) => {
  return {
    snackbarOpen: true,
    snackbarType: 'success',
    snackbarMessage: message,
    vertical: 'top',
    horizontal: 'center',
  };
};

export const useNotification = () => {
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');

  const handleErrorResponse = (notificationMessage) => {
    setOpenNotification(true);
    setNotificationMessage(notificationMessage);
  };

  const NotificationPopup = () => (
    <NotificationMessagePopup
      openPopUp={openNotification}
      message={notificationMessage}
      setNotificationMessage={setNotificationMessage}
      setOpenNotification={setOpenNotification}
    />
  );

  return {
    openNotification,
    notificationMessage,
    setOpenNotification,
    setNotificationMessage,
    NotificationPopup,
    handleErrorResponse,
  };
};

export const onSave = async (
  message,
  newFlag,
  postApi,
  putApi,
  payload,
  id,
  refresh,
  handleClose,
  setOpenNotification,
  setNotificationMessage,
  dispatch,
  setLoadingButton,
  loadingButton,
  postAdditionalParameter
) => {
  const snackBar = getSnackBar(message);
  if (!loadingButton) {
    setLoadingButton(true);
    try {
      if (newFlag) {
        await postApi(payload, postAdditionalParameter);
        handleClose();
      } else {
        await putApi(payload, id);
        handleClose();
      }
      dispatch(displaySnackbar(snackBar));
      refresh();
      handleClose();
      setLoadingButton(false);
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.message);
      setLoadingButton(false);
    }
  }
};

export const fieldValidation = (maxLength, isRequired = true) => {
  const minCharacter = maxLength === characterLimit;
  const maxCharacter = maxLength === 4000 && requiredTextLimit;
  const limit = minCharacter ? requiredLimit : maxCharacter;
  let schema = yup.string().max(maxLength, limit).trim().nullable();
  if (isRequired) {
    schema = schema.required(requiredField);
  }
  return schema;
};

export const minDateValidation = () => {
  return yup
    .date()
    .nullable()
    .min(new Date(), minDateError)
    .typeError(validDateError);
};

export const dropDownValidation = yup
  .string()
  .required(requiredField)
  .nullable();

export const handleInputChange = (onChange) => (e) => {
  onChange(e.target.value.trimStart().replace(/\s+/g, ' '));
};

export const useResetSnackbar = () => {
  const dispatch = useDispatch();
  const snackbarOpen = useSelector((state) => state?.snackbar?.snackbarOpen);
  useEffect(() => {
    if (snackbarOpen) {
      return () => {
        dispatch(resetSnackbar());
      };
    }
  }, [dispatch, snackbarOpen]);
};

export const useMembersState = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [membersDialog, setMembersDialog] = useState(false);
  const [listName, setListName] = useState('');

  const handleMembers = (formName, headingName) => {
    return (params) => {
      setLoading(true);
      const { row } = params;
      dispatch(groupUsers(row));
      dispatch(setFormName(formName));
      setListName(headingName);
      setTimeout(() => {
        setLoading(false);
        setMembersDialog(true);
      }, 300);
    };
  };

  return { loading, membersDialog, handleMembers, setMembersDialog, listName };
};

export const formatDate = (date) => {
  if (!date) {
    return null;
  }
  const getDate = new Date(date?.split('T')[0]);
  return format(getDate, dateFnsFormat);
};

export const clearSpace = (str) => {
  if (str === null) {
    return null;
  }
  return str.trim().replace(/\s+/g, ' ');
};

export const mapNoneOption = (options) => {
  return (options ?? [])?.map((option) => ({
    id: option,
    value: option,
  }));
};

export const emptyFunction = () => {};

export const DropdownInputList = ({ selectedValues }) => {
  return (
    <>
      {selectedValues.map((value, index) => (
        <DropdownInputListBox key={index}>
          {value}
          <br />
        </DropdownInputListBox>
      ))}
    </>
  );
};

export const useListDialog = () => {
  const [dialogName, setDialogName] = useState('');
  const [openViewDialog, setOpenViewDialog] = useState(false);

  const ListDialog = () => (
    <RenderListDialog
      dialogName={dialogName}
      setDialogName={setDialogName}
      open={openViewDialog}
      setOpen={setOpenViewDialog}
    />
  );
  return {
    setDialogName,
    setOpenViewDialog,
    ListDialog,
  };
};

export const formatDateText = (dateString) => {
  if (!dateString) {
    return null;
  }
  const dateObj = new Date(dateString);
  const day = dateObj.getDate().toString().padStart(2, '0');
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
  const year = dateObj.getFullYear().toString();
  return `${month}-${day}-${year}`;
};

export const truncateText = (str) => {
  return str.length > 10 ? str.substring(0, 12) + '...' : str;
};

export const handleBooleanProp = (booleanValue) =>
  booleanValue ? 1 : undefined;

const getFileExtension = (filename) => {
  const lastDotIndex = filename.lastIndexOf('.');
  if (lastDotIndex === -1) return ''; // Return an empty string if no dot is found
  return filename.slice(lastDotIndex + 1);
};

export const getFileIcon = (filename) => {
  const extension = getFileExtension(filename)?.toLowerCase();

  const fileIconMap = {
    image: ['jpg', 'png', 'svg', 'jpeg', 'apng', 'webp'],
    gif: ['gif'],
    video: ['mp4', 'mkv', 'mov', 'wmv', 'avchd', 'avi', 'mpeg-2'],
    text: ['txt', 'dat', 'org'],
    msword: ['docx', 'doc'],
    pdf: ['pdf'],
    excel: ['xls', 'xlsx'],
    music: ['mp3', 'msv', 'mpc'],
  };

  const iconKeys = Object.keys(fileIconMap);
  for (const key of iconKeys) {
    if (fileIconMap[key].includes(extension)) {
      return `${key}-icon`;
    }
  }
  return 'msword-icon';
};

export const testComponent = (component) => {
  return (
    <Authenticator.Provider>
      <Provider store={store}>
        <BrowserRouter>
          <MaterialUIControllerProvider>
            <ThemeProvider theme={themeRtl}>
              <CssBaseline />
              {component}
            </ThemeProvider>
          </MaterialUIControllerProvider>
        </BrowserRouter>
      </Provider>
    </Authenticator.Provider>
  );
};

export const TimeOutPopup = ({ seconds, setSeconds, signOut }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogoutTiming = useSelector((state) => state.logout.isLogoutTiming);

  const handleYes = () => {
    dispatch(setIsLogoutTiming(false));
    setSeconds(60);
  };

  const handleNo = () => {
    dispatch(setIsLogoutTiming(false));
    dispatch(setIsLogout(true));
    dispatch(resetSelectSemester());
    setSeconds(60);
    navigate('/');
    signOut();
    setTimeout(() => {
      dispatch(resetIsLogout());
      dispatch(resetMyPermissionsData());
    }, 2000);
  };

  return (
    <Dialog open={isLogoutTiming} PaperProps={TimeOutDialog}>
      <TimeOutGrid container>
        <Typography variant="h6">
          Your session will expire in {seconds} seconds. Do you want to extend
          the session ?
        </Typography>
      </TimeOutGrid>
      {getYesNo(handleNo, handleYes)}
    </Dialog>
  );
};

export const onRedirect = async (
  setIsLoading,
  redirectApi,
  id,
  dispatch,
  dispatchFunction,
  navigate,
  url,
  setOpenNotification,
  setNotificationMessage
) => {
  try {
    setIsLoading(true);
    const data = await redirectApi(id);
    dispatch(dispatchFunction(data));
    navigate(url);
    setIsLoading(false);
  } catch (error) {
    setOpenNotification(true);
    setNotificationMessage(error?.response?.data?.message);
    setIsLoading(false);
  }
};

export const useRenderNoInfo = (data, message) => {
  if (data?.length === 0) {
    return <NoInfo message={message} />;
  }
  return null;
};

export const getOptionLabel = (option) =>
  `${option.firstName} ${option.lastName} ${option.studentId}`;

export const dropDownSameOptions = (items) => {
  return items?.map((item) => ({ id: item, value: item })) ?? [];
};

export const onPageSizeChange = (props) => {
  const { setListData, newPageSize } = props;

  setListData((prveState) => ({
    ...prveState,
    pageSize: newPageSize,
  }));
};

export const pageSizeChangeHandler = (setListData) => {
  return (newPageSize) => {
    onPageSizeChange({ setListData, newPageSize });
  };
};

export const hasDuplicates = (array) => {
  const uniqueValues = new Set(array);
  return uniqueValues.size !== array.length;
};

export const dropdownTruncateText = (str) => {
  return str.length > 30 ? str.substring(0, 30) + '...' : str;
};

export const truncateTooltipText = (value) => {
  const shouldTruncate = value.length > 30;
  const textToDisplay = shouldTruncate ? dropdownTruncateText(value) : value;

  const renderText = (text) => <Typography variant="text">{text}</Typography>;

  if (!shouldTruncate) {
    return renderText(textToDisplay);
  }

  return <WhiteTooltip title={value}>{renderText(textToDisplay)}</WhiteTooltip>;
};

export const permissionCheck = (
  permissionCheck,
  callback,
  handleErrorResponse,
  errorMessage,
  params = {}
) => {
  if (permissionCheck) {
    callback(params);
  } else {
    handleErrorResponse(errorMessage);
  }
};

export const maxDateValidation = (dependentField, errorMessage) =>
  yup
    .date()
    .required(requiredField)
    .typeError(requiredField)
    .test('is-greater-or-equal', errorMessage, function (value) {
      const dependentValue = this.parent[dependentField];
      return (
        !dependentValue ||
        !value ||
        isSameDay(value, dependentValue) ||
        isAfter(value, dependentValue)
      );
    });

export const renderSelectedValue = (selected) => {
  return <NoWrap>{selected.join(', ')}</NoWrap>;
};

export const timezoneOffset = new Date().getTimezoneOffset() > 0 ? 2 : 1;

export const formatTimeZoneDate = (date) => {
  if (!date) return null;
  const momentDate = moment(date);

  return timezoneOffset === 1
    ? momentDate.format(dateFormat)
    : momentDate.add(-1, 'day').format(dateFormat);
};

export const getSaveforlaterContinue = (
  handleSaveForLater,
  handleContinue,
  loading
) => {
  return (
    <ButtonWrapper container>
      <Button variant="text" onClick={handleSaveForLater}>
        Save for later
      </Button>
      <ButtonSpinner>
        <Box sx={{ m: 1 }}>
          <Button variant="text" onClick={handleContinue} disabled={loading}>
            Continue
          </Button>
          {loading && <ButtonSpinnerProgress size={24} />}
        </Box>
      </ButtonSpinner>
    </ButtonWrapper>
  );
};

export const useSortModel = (defaultField = 'name', defaultSort = 'asc') => {
  const [sortModel, setSortModel] = useState([
    { field: defaultField, sort: defaultSort },
  ]);

  const handleSortModelChange = (newModel) => {
    if (newModel.length === 0) {
      setSortModel([{ field: defaultField, sort: defaultSort }]);
      return;
    }

    const clickedField = newModel[0].field;
    const currentField = sortModel[0]?.field;
    const currentSort = sortModel[0]?.sort;

    const newSort =
      clickedField === currentField && currentSort === 'asc' ? 'desc' : 'asc';
    setSortModel([{ field: clickedField, sort: newSort }]);
  };

  return [sortModel, handleSortModelChange];
};

export const getDragAndDroppedColumns = (arr1, arr2) => {
  return arr1.some((item, index) => item !== arr2[index]);
};

export const getNewColumns = (arr1, arr2) => {
  const newColumns = arr2.filter((item) => !arr1.includes(item));
  return newColumns;
};

export const getRenamedColumns = (arr1, arr2) => {
  let renamedColumns = [];
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      renamedColumns.push({ original: arr1[i], edited: arr2[i] });
    }
  }
  return renamedColumns;
};

export const currentDate = new Date();

export const dropDownOptions = (items, id, value) => {
  return items?.map((item) => ({ id: item[id], value: item[value] })) ?? [];
};

export const showDialogFormLoader = () => (
  <DialogFormLoader data-testid="dialogFormLoader">
    <LoadingSpinner />
  </DialogFormLoader>
);

export const refinePayload = (payload) => {
  return Object.fromEntries(
    Object.entries(payload).map(([key, value]) => {
      if (typeof value === 'string') {
        return [key, clearSpace(value)];
      }
      return [key, value];
    })
  );
};

export const camelCaseToTitleCase = (text) => {
  return text
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/^./, (str) => str.toUpperCase());
};

export const camelCaseCapitalizeName = (key) => {
  return key
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Convert camelCase to spaces (e.g., "outputType" -> "Output Type")
    .replace(/_/g, ' ') // Replace underscores with spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter of each word
};

export const isDateValue = (value) => {
  return typeof value === 'string' && !isNaN(Date.parse(value));
};

export const dynamicDefaultValue = (data) => {
  return Object.entries(data).reduce((acc, [key, value]) => {
    if (isDateValue(value)) {
      acc[key] = formatDate(value);
    } else if (Array.isArray(value)) {
      acc[key] = value.join(', ');
    } else {
      acc[key] = value;
    }
    return acc;
  }, {});
};
