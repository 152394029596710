import React, { useEffect } from 'react';
import MDBox from 'components/MDBox';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import { useForm } from 'react-hook-form';
import { GridForm } from 'components/Ui/styled';
import { useDispatch, useSelector } from 'react-redux';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import { PaperPropsWithoutScrollbar } from 'constants';
import {
  getDialogHeading,
  showBackdropLoader,
  dynamicDefaultValue,
  camelCaseToTitleCase,
} from 'utils/commonUtils';
import { FormInputSwitch } from 'components/Common/Forms/FormInputSwitch';
import { resetReportExecution } from 'layouts/reportTool/store/reportExecutionSlice/reportExecutionSlice';
import { viewReportExecutionFields } from './ViewReportExecutionFields';

const ViewReportExecutionDialog = (props) => {
  const { open, setOpen, viewDialogLoading } = props;
  const dispatch = useDispatch();
  const reportExecutionForm =
    useSelector((state) => state?.reportExecutionForm) || {};

  const { reportParams, id, metadata, ...mainData } = reportExecutionForm || {};
  const flattenedData = { ...mainData, ...reportParams };

  const dynamicForm = dynamicDefaultValue(flattenedData);

  const { reset, control } = useForm({
    mode: 'onChange',
    defaultValues: dynamicForm,
  });

  useEffect(() => {
    if (open) {
      reset(dynamicForm);
    }
  }, [open, reset]);

  const handleClose = () => {
    setOpen(false);
    dispatch(resetReportExecution());
  };

  const { textFields, switchFields } = viewReportExecutionFields(
    dynamicForm,
    control
  );

  return (
    <>
      {showBackdropLoader(viewDialogLoading)}
      <Dialog open={open} PaperProps={PaperPropsWithoutScrollbar}>
        {getDialogHeading('Report Execution', handleClose)}
        <GridForm container spacing={1}>
          {textFields}
        </GridForm>
        <GridForm container spacing={1}>
          {switchFields}
        </GridForm>
        <MDBox mb={3} />
      </Dialog>
    </>
  );
};

export default ViewReportExecutionDialog;
