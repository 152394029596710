import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import * as yup from 'yup';
import { GridContainer } from 'components/Ui/styled';
import {
  setColor,
  setCategoryId,
} from 'layouts/applications/calendar/components/Categories/store/calenderCategorySlice';
import { postCategory, putCategory } from '../services/categoryApi';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomizedSnackbars from 'components/snackbar/Snackbar';
import {
  PaperProps,
  requiredLimit,
  categorySuccess,
  characterLimit,
} from 'constants';
import {
  renderSaveCancelButtons,
  getDialogHeading,
  onSave,
  useNotification,
  refinePayload,
} from 'utils/commonUtils';

const categorySchema = yup.object().shape({
  name: yup
    .string()
    .max(characterLimit, requiredLimit)
    .required('category name cannot be empty')
    .nullable(),
  color: yup.string().required('category color cannot be empty'),
});

const CategoryDialog = (props) => {
  const dispatch = useDispatch();
  const { open, setOpen, fetchCalendarDataOfParticularMonth } = props;

  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  const [loadingButton, setLoadingButton] = useState(false);

  const currentCalenderId = useSelector(
    (state) => state?.Calender?.selectCalendar
  );
  const calendarCategory = useSelector((state) => state?.calendarCategory);

  const yearMonth = useSelector((state) => state.yearMonth);

  const current = new Date(yearMonth);
  const getCurrentYear = `${current.getFullYear()}`;
  const getCurrentMonth = `${current.getMonth() + 1}`;

  const { newCategory, color, categories, name, calendarId, categoryId } =
    calendarCategory || {};

  const defaultValue = {
    category: categories,
    color: color,
    name: name,
    calendarId: calendarId,
    categoryId: categoryId,
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    resolver: yupResolver(categorySchema),
  });

  const { handleSubmit, reset, control, register, formState } = useFunction;
  const { errors } = formState;

  useEffect(() => {
    reset(defaultValue);
  }, [
    defaultValue.category,
    defaultValue.color,
    defaultValue.name,
    defaultValue.categoryId,
  ]);

  const handleClose = () => {
    setOpen(false);
    reset(defaultValue);
  };

  const onSubmit = async () => {
    const payload = {
      color: color,
      name: name,
      categoryId: newCategory ? undefined : categoryId,
    };
    onSave(
      categorySuccess,
      newCategory,
      () => {
        postCategory(refinePayload(payload), currentCalenderId);
      },
      putCategory,
      refinePayload(payload),
      currentCalenderId,
      () => fetchCalendarDataOfParticularMonth(getCurrentYear, getCurrentMonth),
      handleClose,
      setOpenNotification,
      setNotificationMessage,
      dispatch,
      setLoadingButton,
      loadingButton
    );
  };

  return (
    <>
      <CustomizedSnackbars />
      <Dialog open={open} PaperProps={PaperProps}>
        <>
          {getDialogHeading('Category', handleClose)}
          <GridContainer container>
            <FormInputText
              name="name"
              type="text"
              control={control}
              label="Category"
              helperText={errors?.name?.message}
              errors={!!errors.name}
              register={register('name', {
                onChange: (e) => dispatch(setCategoryId(e.target.value)),
              })}
            />

            <FormInputText
              name="color"
              type="color"
              control={control}
              label="Color"
              helperText={errors?.color?.message}
              errors={!!errors.color}
              register={register('color', {
                onChange: (e) => dispatch(setColor(e.target.value)),
              })}
            />
            {renderSaveCancelButtons(
              handleClose,
              handleSubmit(onSubmit),
              loadingButton
            )}
          </GridContainer>
        </>
      </Dialog>
      <NotificationPopup />
    </>
  );
};

export default CategoryDialog;
