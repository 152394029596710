import Grid from '@mui/material/Grid';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import { FormInputSwitch } from 'components/Common/Forms/FormInputSwitch';
import { camelCaseToTitleCase } from 'utils/commonUtils';

export const viewReportExecutionFields = (dynamicForm, control) => {
  const textFields = [];
  const switchFields = [];

  Object.entries(dynamicForm).forEach(([key, value]) => {
    if (typeof value === 'boolean') {
      switchFields.push(
        <Grid item xs={12} sm={6} key={key}>
          <FormInputSwitch
            control={control}
            name={key}
            label={camelCaseToTitleCase(key)}
            readOnly
          />
        </Grid>
      );
    } else {
      textFields.push(
        <Grid item xs={12} sm={6} key={key}>
          <FormInputText
            name={key}
            label={camelCaseToTitleCase(key)}
            control={control}
            readOnly
          />
        </Grid>
      );
    }
  });

  return { textFields, switchFields };
};
