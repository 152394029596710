import { format } from 'date-fns';
import { dateFnsFormat } from 'constants';
import { camelCaseCapitalizeName, isDateValue } from 'utils/commonUtils';
import { renderCellExpand } from 'components/Common/GridCell/GridCellExpand';
import { renderReportToolExecutionAction } from './utils/reportToolComponentUtils';
import { TextAlignCenter } from 'components/Ui/styled';

export const getReportExecutionColumns = (
  reportExecution,
  handleDownloadReportExecution,
  handleViewReportExecution,
  handleDeleteReportExecution
) => {
  if (!reportExecution?.items || reportExecution.items.length === 0) {
    return [];
  }

  let columnKeys = new Set(Object.keys(reportExecution.items[0]));
  columnKeys.delete('id');
  columnKeys.delete('metadata');
  reportExecution.items.forEach((item) => {
    if (item.reportParams && typeof item.reportParams === 'object') {
      Object.keys(item.reportParams).forEach((paramKey) => {
        columnKeys.add(paramKey);
      });
    }
  });
  columnKeys.delete('reportParams');

  const actionColumn = [
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      disableColumnMenu: true,
      width: 120,
      renderCell: (params) =>
        renderReportToolExecutionAction(
          params,
          handleDownloadReportExecution,
          handleViewReportExecution,
          handleDeleteReportExecution
        ),
    },
  ];

  let normalColumns = [];
  let dateColumns = [];

  Array.from(columnKeys).forEach((key) => {
    const sampleValue = reportExecution.items.find(
      (item) => item[key] !== undefined
    )?.[key];

    const column = {
      field: key,
      headerName: camelCaseCapitalizeName(key),
      renderCell: (params) => {
        let value = params.value;
        if (typeof value === 'boolean') {
          return <TextAlignCenter>{value ? 'Yes' : 'No'}</TextAlignCenter>;
        }
        if (isDateValue(value)) {
          return format(new Date(value), dateFnsFormat);
        }
        if (typeof value === 'object' && value !== null) {
          return Array.isArray(value)
            ? value.join(', ')
            : JSON.stringify(value);
        }
        return renderCellExpand({ ...params, value });
      },
      disableColumnMenu: true,
      width: key === 'report' ? 200 : 150,
    };

    if (isDateValue(sampleValue)) {
      dateColumns.push(column);
    } else {
      normalColumns.push(column);
    }
  });

  return [...actionColumn, ...normalColumns, ...dateColumns];
};

export const getReportExecutionRow = (reportExecution) => {
  if (!reportExecution?.items || reportExecution.items.length === 0) {
    return [];
  }

  return reportExecution.items.map((row, index) => ({
    id: index,
    ...row,
    ...(row.reportParams && typeof row.reportParams === 'object'
      ? Object.fromEntries(
          Object.entries(row.reportParams).map(([key, value]) => [
            key,
            Array.isArray(value) ? value.join(', ') : value,
          ])
        )
      : {}),
  }));
};