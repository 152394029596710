import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import { GridForm, GridButtonWrapper } from 'components/Ui/styled';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  getDialogHeading,
  useNotification,
  renderSaveCancelButtons,
  showBackdropLoader,
  emptyFunction,
  onSave,
  showDialogFormLoader,
  clearSpace,
} from 'utils/commonUtils';
import {
  PaperPropsCheck,
  reportExecutionSuccess,
  requiredField,
} from 'constants';
import { useDispatch } from 'react-redux';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import {
  getAvailableReport,
  postReportExecution,
} from 'layouts/reportTool/services/reportToolApi';
import ReportExecutionDynamicForm from './ReportExecutionDynamicForm';
import { generateReportExecutionDefaultValues } from '../utils/reportToolComponentUtils';

const ReportExecutionDialog = (props) => {
  const dispatch = useDispatch();
  const {
    open,
    setOpen,
    isFormLoading,
    availableReportOptions,
    fetchReportExecutions,
  } = props;
  const {
    setOpenNotification,
    setNotificationMessage,
    handleErrorResponse,
    NotificationPopup,
  } = useNotification();

  const [availableReport, setAvailableReport] = useState(null);
  const [availableReports, setAvailableReports] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const generateReportExecutionSchema = (params) => {
    const shape = {};
    if (availableReport?.params) {
      Object.entries(params).forEach(([key, field]) => {
        if (field.required) {
          if (field.fieldType === 'bool') {
            shape[key] = yup
              .boolean()
              .test('is-true', requiredField, (value) => value === true);
          } else {
            shape[key] = yup.string().required(requiredField);
          }
        }
      });
    }
    return yup.object().shape(shape);
  };

  const reportExecutionschema = generateReportExecutionSchema(availableReport?.params);

  const defaultValue = {
    availableReports: availableReports,
    description: '',
    ...generateReportExecutionDefaultValues(availableReport?.params),
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    resolver: yupResolver(reportExecutionschema),
  });

  const { handleSubmit, reset, control, formState, register } = useFunction;
  const { errors } = formState;

  useEffect(() => {
    reset(defaultValue);
  }, [
    defaultValue.availableReports,
    defaultValue.description,
    availableReport,
  ]);

  const handleClose = () => {
    setOpen(false);
    setAvailableReports(null);
    reset(defaultValue);
    setAvailableReport(null);
  };

  const handleAvailableReportsChange = async (e) => {
    setAvailableReports(e.target.value);
    try {
      reset(defaultValue);
      setIsLoading(true);
      const availableReport = await getAvailableReport(e.target.value);
      setAvailableReport(availableReport);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(true);
      handleErrorResponse(error?.response?.data?.message);
    }
  };

  const onSubmit = async (data) => {
    const { availableReports, description, ...reportParams } = data;

    const myPayload = {
      report: availableReports,
      description: clearSpace(description),
      reportParams: { ...reportParams },
    };

    onSave(
      reportExecutionSuccess,
      true,
      postReportExecution,
      emptyFunction,
      myPayload,
      '',
      fetchReportExecutions,
      handleClose,
      setOpenNotification,
      setNotificationMessage,
      dispatch,
      setLoadingButton,
      loadingButton
    );
  };

  return (
    <>
      {showBackdropLoader(isFormLoading)}
      <Dialog open={open} PaperProps={PaperPropsCheck}>
        {getDialogHeading('Report Execution', handleClose)}
        <GridForm container spacing={2}>
          <Grid item xs={12}>
            <FormInputDropdown
              name="availableReports"
              control={control}
              options={availableReportOptions}
              label="Available Reports"
              helperText={errors?.availableReports?.message}
              errors={!!errors.availableReports}
              register={register('availableReports', {
                onChange: (e) => {
                  handleAvailableReportsChange(e);
                },
              })}
            />
            <FormInputText
              name="description"
              type="text"
              label="Description"
              control={control}
            />
          </Grid>
          {isLoading ? (
            <Grid item xs={12}>
              {showDialogFormLoader()}
            </Grid>
          ) : (
            availableReport?.params && (
              <ReportExecutionDynamicForm
                control={control}
                params={availableReport?.params}
                errors={errors}
              />
            )
          )}
        </GridForm>
        <GridButtonWrapper container>
          {renderSaveCancelButtons(
            handleClose,
            handleSubmit(onSubmit),
            loadingButton,
            !availableReports
          )}
        </GridButtonWrapper>
      </Dialog>
      <NotificationPopup />
    </>
  );
};

export default ReportExecutionDialog;